import { toggleHamburger } from "./toggleHamburger";
import { initializeViewport } from "./initializeViewport";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js"; // 修正箇所
toggleHamburger("js-hamburger", "js-spmenu", 1024);
initializeViewport();

document.addEventListener("DOMContentLoaded", function () {
  const scrollToTopButton = document.querySelector(".js-button-pagetop");
  if (scrollToTopButton) {
    scrollToTopButton.addEventListener("click", function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    });
  }
});

gsap.registerPlugin(ScrollTrigger);

document.addEventListener("DOMContentLoaded", function () {
  // Three.js のセットアップ
  const scene = new THREE.Scene();
  const camera = new THREE.PerspectiveCamera(75, 800 / 400, 0.1, 1000);
  const renderer = new THREE.WebGLRenderer();
  renderer.setSize(800, 400);
  renderer.setClearColor(0xffffff, 1); // 0xffffff は白色、1 は透明度（1で不透明）
  // Three.js の Canvas を Splide の最初のスライドに挿入
  const threeSlide = document.querySelector(".splide__slide:first-child");
  threeSlide.innerHTML = ""; // 元の内容をクリア
  threeSlide.appendChild(renderer.domElement);

  // 環境光と方向光を追加
  const ambientLight = new THREE.AmbientLight(0x404040, 10); // 環境光
  scene.add(ambientLight);

  const directionalLight = new THREE.DirectionalLight(0xff0000, 10); // 方向光
  directionalLight.position.set(5, 10, 7.5);
  scene.add(directionalLight);

  // GLTFLoader を使用して GLB ファイルを読み込む
  const loader = new GLTFLoader(); // 修正箇所
  let model; // モデルの参照を保持
  loader.load(
    "/wp-content/themes/ichitosanbunnoichi/assets/model/test.glb", // GLB ファイルのパス
    function (gltf) {
      model = gltf.scene;
      scene.add(model);
      model.position.set(0, 0, 0);
      model.scale.set(1, 1, 1);
    },
    undefined,
    function (error) {
      console.error("An error happened", error);
    }
  );

  // カメラ位置を設定
  camera.position.z = 5;

  // アニメーションループ
  function animate() {
    requestAnimationFrame(animate);

    // モデルを自動回転
    if (model) {
      model.rotation.y += 0.01; // 横方向の回転
    }

    renderer.render(scene, camera);
  }
  animate();
});
